export {navigationTree, navigationSearch}

import {backendUrl, parseResponse} from "./api";

function navigationTree(authToken) {
    return new Promise(function (resolve) {
        let opt = {
            headers: {
                Authorization: 'Bearer ' + authToken,
            }
        }
        fetch(backendUrl + "/api/v1/configuration/navigation/documents", opt)
            .then((response) => {
                parseResponse(response)
                    .then((r) => resolve(r))
            })
    })
}

function navigationSearch(params, authToken) {
    return new Promise(function (resolve) {
        fetch(backendUrl+"/api/v1/configuration/navigation/search?search="+encodeURIComponent(params.search)+'&fields='+encodeURIComponent(params.fields), {
            headers: {
                Authorization: 'Bearer ' + authToken,
            }
        }).then((response) => {
            parseResponse(response)
                .then((r) => resolve(r))
        })
    })
}