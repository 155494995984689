<template>
  <div>
    <v-row>
      <v-col>
        <v-card class="mx-10 mt-10" elevation="4" v-if="user && user.isAdmin">
          <v-card-title>Пользователи онлайн</v-card-title>
          <v-card-text style="text-align: left;" >
             <span v-for="u in activeUsers" :key="u.id">
            {{u.name}} ({{u.id}}): {{u.lastOperationDuration}}<br>
          </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="ma-10" elevation="4" v-if="user && user.isAdmin">
          <v-card-title>{{user.fullName}}</v-card-title>
          <v-card-text style="text-align: left;">token: {{user.token}}</v-card-text>
          <v-card-text style="text-align: left;">dn: {{user.dn}}</v-card-text>
          <v-card-text style="text-align: left;">groups:
            <div v-for="g in user.groups" :key="g">{{g}}<br></div></v-card-text>
          <v-card-text style="text-align: left;">title: {{user.title}}</v-card-text>
          <v-card-text style="text-align: left;">email: {{user.email}}</v-card-text>
          <v-card-text style="text-align: left;">phone: {{user.phone}}</v-card-text>
          <v-card-text style="text-align: left;">isAdmin: {{user.isAdmin ? 'yes': 'no'}}</v-card-text>
          <v-card-text style="text-align: left;">personalEqmID: {{user.personalEqmID}}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import {activeUsersList} from "../api/other";

export default {
  name: 'Home',
  data: function () {
    return {
      activeUsers: [],
    }
  },
  watch: {
    $route() {
      this.init()
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    init() {
      activeUsersList(this.$store.getters.getAuthToken)
      .then(r => {
        if (r.success)
          this.activeUsers = r.body.value
      })
    }
  },
  mounted() {
    this.init()
  }
}
</script>
