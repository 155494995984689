import Vue from 'vue'
import Vuex from 'vuex'
import configurations from './modules/configurations'
import navigation from './modules/navigation'
import document from './modules/document'
import user from './modules/user'
import personal from './modules/personal'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    backendUrl: 'http://10.1.251.70:9099', // http://10.1.251.70:9099' https://eqm.maximatelecom.ru:9099
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    configurations,
    navigation,
    document,
    user,
    personal,
  }
})
