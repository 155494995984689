export default {
    state: {
        authorized: false,
        user: {
            token: '',
        },
    },
    mutations: {
        setNewLogin(state, data) {
            localStorage.setItem('userMeta', JSON.stringify(data));
            // localStorage.setItem('userAuthToken', data.token);
            // localStorage.setItem('userLogin', data.name);
            state.user = data
            state.authorized = true
        },
        resetLogin(state) {
            state.authorized = false
            state.user = {}
            localStorage.removeItem('userMeta')
            // localStorage.removeItem('userAuthToken')
            // localStorage.removeItem('userLogin')
        },
        setLoginFromLocalStore(state) {
            state.user = JSON.parse(localStorage.getItem('userMeta'))
            // state.token = localStorage.getItem('userAuthToken')
            // state.user.name = localStorage.getItem('userLogin')
            if (state.user && state.user.token && state.user.token.length > 1) {
                state.authorized = true
            }
        },
    },
    actions: {
        async logout(ctx) {
            ctx.commit('resetLogin')
        },
    },
    getters: {
        isAuthorized(state) {
            return state.authorized;
        },
        getUser(state) {
            return state.user;
        },
        getAuthToken(state) {
            if (state.user) {
                return state.user.token
            }
            return ""
        },
    },
    modules: {}
}