import {navigationSearch, navigationTree} from "../../api/navigation";

export default {
    state: {
        documents: [],
        searchResults: [],
    },
    mutations: {
        updateDocuments(state, navData) {
            let id = 1;
            state.documents = navData.map((elem) => {
                return {
                    id: id++,
                    name: elem.group_name,
                    children: elem.document_types.map((elem) => {
                        return {
                            id: id++,
                            name: elem.type_name,
                            typeId: elem.type_id
                        }
                    })
                }
            })
        },
        saveSearchResults(state, data) {
            state.searchResults = data
        },
        cleanUpSearch(state) {
            state.searchResults = []
        }
    },
    actions: {
        async fetchDocumentsNavigation(ctx) {
            navigationTree(ctx.getters.getAuthToken)
                .then((r) => {
                    if (r.success) {
                        ctx.commit('updateDocuments', r.body)
                    } else if (r.status === 403) {
                        ctx.commit('resetLogin')
                    } else {
                        console.log('navigationTree req not success', r)
                    }
                })
        },
        async search(ctx, params) {
            navigationSearch(params, ctx.getters.getAuthToken)
                .then((r) => {
                    if (r.success) {
                        ctx.commit('saveSearchResults', r.body)
                    } else {
                        console.log('navigationTree req not success', r)
                    }
                })
        }
    },
    getters: {
        appNavigationItems(state) {
            return state.documents
        },
        searchResults(state) {
            return state.searchResults
        }
    }
}