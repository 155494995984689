export {apiLogin, loadFileLink, parseResponse}

export const backendUrl = 'http://10.1.251.70:9099' // https://eqm.maximatelecom.ru:9099

async function parseResponse(response, successStatuses) {
    if (!successStatuses) {
        successStatuses = [200]
    }
    if (successStatuses.includes(response.status)) {
        return {
            success: true,
            status: response.status,
            body: await response.json()
        }
    }
    return {
        success: false,
        status: response.status,
        body: null
    }
}

function apiLogin(username, password) {
    return new Promise(function (resolve) {
        fetch(backendUrl + "/api/login/", {
            method: 'POST',
            body: JSON.stringify({username, password})
        }).then((response) => {
            parseResponse(response)
                .then((r) => resolve(r))
        })
    })
}

function loadFileLink(fileID) {
    return backendUrl+'/api/v1/download-file/'+fileID;
}