export {remarkDelete, activeUsersList}

import {backendUrl, parseResponse} from "./api";

function remarkDelete(remarkID, authToken) {
    return new Promise(function (resolve) {
        fetch(backendUrl + "/api/v1/remarks/" + remarkID, {
            method: 'DELETE',
            headers: {
                Authorization: 'Bearer ' + authToken,
            }
        }).then((response) => {
            resolve({
                success: 200 === response.status,
                status: response.status,
            })
        })
    })
}

function activeUsersList(authToken) {
    return new Promise(function (resolve) {
        fetch(backendUrl + "/api/v1/active-users/", {
            headers: {
                Authorization: 'Bearer ' + authToken,
            }
        }).then((response) => {
            parseResponse(response)
                .then((r) => resolve(r))
        })
    })
}