import {
    configurationAttributes,
    configurationReference,
    configurationStates,
    configurationTypeData
} from "../../api/configuration";

export default {
    state: {
        typeData: {},
        attributes: [],
        states: {
            states: [],
            routes: [],
        },
        reference: {}
    },
    mutations: {
        clearConfiguration(state) {
            state.typeData = {}
            state.attributes = []
            state.states = {
                states: [],
                routes: [],
            }
            state.reference = {
            }
        }
        ,
        updateTypeData(state, data) {
            state.typeData = data
        },
        updateAttributes(state, data) {
            state.attributes = data
        },
        updateStates(state, data) {
            state.states = data
        },
        updateCurrentReference(state, data) {
            state.reference = data
        }
    },
    actions: {
        async fetchTypeData(ctx, typeID) {
            configurationTypeData(typeID, ctx.getters.getAuthToken)
                .then((r) => {
                    if (r.success) {
                        ctx.commit('updateTypeData', r.body)
                    } else {
                        console.log('configurationTypeData req not success', r)
                    }
                })
        },
        async fetchAttributes(ctx, typeID) {
            configurationAttributes(typeID, ctx.getters.getAuthToken)
                .then((r) => {
                    if (r.success) {
                        ctx.commit('updateAttributes', r.body)
                    } else {
                        console.log('configurationAttributes req not success', r)
                    }
                })
        },
        async fetchStates(ctx, typeID) {
            configurationStates(typeID, ctx.getters.getAuthToken)
                .then((r) => {
                    if (r.success) {
                        ctx.commit('updateStates', r.body)
                    } else {
                        console.log('configurationStates req not success', r)
                    }
                })
        },
        async fetchReference(ctx, id) {
            configurationReference(id, ctx.getters.getAuthToken)
                .then((r) => {
                    if (r.success) {
                        ctx.commit('updateCurrentReference', r.body)
                    } else {
                        console.log('configurationReference req not success', r)
                    }
                })
        }
    },
    getters: {
        allAttributes(state) {
            return state.attributes;
        },
        typeData(state) {
            return {
                typeId: state.typeData.type_id,
                typeName: state.typeData.type_name,
                firstState: state.typeData.first_state,
                cft: state.typeData.cft,
                remark: state.typeData.remark,
                visible: state.typeData.visible,
                guid: state.typeData.guid,
                captionTemplate: state.typeData.caption_template,
            }
        },
        statesData(state) {
            return state.states
        },
        referenceData(state) {
            return state.reference
        }
    },
    modules: {
    }
}