export {
    documentsList,
    documentsListMeta,
    documentCurrent,
    documentAddComment,
    documentAddFile,
    documentMoveState,
    documentsListDownload,
    documentCreateMeta,
    documentCreate,
}

import {backendUrl, parseResponse} from "./api";

function documentCurrent(docID, authToken) {
    return new Promise(function (resolve) {
        fetch(backendUrl + "/api/v1/documents/" + docID, {
            headers: {
                Authorization: 'Bearer ' + authToken,
            }
        }).then((response) => {
            parseResponse(response)
                .then((r) => resolve(r))
        })
    })
}

function documentsListMeta(typeID, authToken) {
    return new Promise(function (resolve) {
        fetch(backendUrl + "/api/v1/documents/list-meta/" + typeID, {
            headers: {
                Authorization: 'Bearer ' + authToken,
            }
        }).then((response) => {
            parseResponse(response)
                .then((r) => resolve(r))
        })
    })
}

function documentsList(f, authToken) {
    if (f.fields.length === 0) {
        return
    }
    let params = [
        'active=' + encodeURIComponent(f.activeType),
        'startDate=' + encodeURIComponent(f.startDate),
        'endDate=' + encodeURIComponent(f.endDate),
        'fields=' + encodeURIComponent(f.fields.join(',')),
    ]
    return new Promise(function (resolve) {
        fetch(backendUrl + "/api/v1/documents/list/" + f.typeID + "?" + params.join('&'), {
            headers: {
                Authorization: 'Bearer ' + authToken,
            }
        }).then((response) => {
            parseResponse(response)
                .then((r) => resolve(r))
        })
    })
}

function documentsListDownload(f, authToken) {
    if (f.fields.length === 0) {
        return
    }
    let params = [
        'active=' + encodeURIComponent(f.activeType),
        'startDate=' + encodeURIComponent(f.startDate),
        'endDate=' + encodeURIComponent(f.endDate),
        'fields=' + encodeURIComponent(f.fields.join(',')),
    ]
    return new Promise(function (resolve) {
        fetch(backendUrl + "/api/v1/documents/list/" + f.typeID + '/download' +"?" + params.join('&'), {
            headers: {
                Authorization: 'Bearer ' + authToken,
            }
        }).then((response) => {
            response.blob()
                .then(v => {
                    resolve({
                        success: response.status === 200,
                        body: v
                    })
                })
        })
    })
}

function documentAddComment(docID, comment, employee, authToken) {
    return new Promise(function (resolve) {
        fetch(backendUrl + "/api/v1/documents/"+docID+"/comments", {
            method: 'POST',
            body: JSON.stringify({
                employee: employee,
                comment: comment
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authToken,
            }
        }).then((response) => {
            resolve({
                success: 200 === response.status,
                status: response.status,
            })
        })
    })
}

function documentAddFile(docID, file, remark, employee, authToken) {
    return new Promise(function (resolve) {
        let data = new FormData()
        data.append('file', file)
        data.append('remark', remark)
        data.append('employeeID', employee)

        fetch(backendUrl + '/api/v1/documents/'+docID+'/files', {
            method: 'POST',
            body: data,
            headers: {
                Authorization: 'Bearer ' + authToken,
            }
        }).then((response) => {
            resolve({
                success: 200 === response.status,
                status: response.status,
            })
        })
    })
}

function documentMoveState(docID, employee, state, authToken) {
    return new Promise(function (resolve) {
        fetch(backendUrl + '/api/v1/documents/'+docID+'/states', {
            method: 'POST',
            body: JSON.stringify({
                newState: state,
                employeeID: employee,
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authToken,
            }
        }).then((response) => {
            resolve({
                success: 200 === response.status,
                status: response.status,
            })
        })
    })
}

function documentCreateMeta(typeID, authToken) {
    return new Promise(function (resolve) {
        fetch(backendUrl + "/api/v1/documents/create-meta/" + typeID, {
            headers: {
                Authorization: 'Bearer ' + authToken,
            }
        }).then((response) => {
            parseResponse(response)
                .then((r) => resolve(r))
        })
    })
}

function documentCreate(params, authToken) {
    return new Promise(function (resolve) {
        fetch(backendUrl + "/api/v1/documents/create", {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authToken,
            },
            method: 'POST',
            body: JSON.stringify(params)
        }).then((response) => {
            parseResponse(response)
                .then((r) => resolve(r))
        })
    })
}