import {getAllPersonal} from "../../api/personal";

export default {
    namespaced: true,
    state: {
        personalLoading: false,
        personalList: [],
    },
    mutations: {
        setPersonal(state, data) {
          state.personalList = data
        },
        setLoading(state, l) {
            state.personalLoading = l
        },
    },
    actions: {
        async fetchAllPersonal(ctx) {
            if (ctx.state.personalLoading) {
                return
            }
            ctx.commit('setLoading', true)
            getAllPersonal(ctx.rootGetters.getAuthToken)
                .then((r) => {
                    if (r.success) {
                        ctx.commit('setPersonal', r.body)
                    } else if (r.status === 403) {
                        ctx.commit('resetLogin')
                    } else {
                        console.log('navigationTree req not success', r)
                    }
                })
                .finally(() => {
                    ctx.commit('setLoading', false)
                })
        },
    },
    getters: {
    }
}