import moment from "moment-timezone";
import {documentCurrent, documentsList, documentsListMeta} from "../../api/document";

export default {
    namespaced: true,
    state: {
        haveAccess: false,
        currentDocument: {},
        currentDocumentLoading: false,
        documentsList: [],
        documentsListLoading: false,
        documentsListMeta: {
            typeName: '',
            attrs: [],
        },
        currentFilter: {
            typeID: 0,
            fields: [],
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            activeType: 'active',
        },
    },
    mutations: {
        setAccess(state, a) {
          state.haveAccess = a
        },
        setCurrentDocument(state, data) {
            state.currentDocument = data
        },
        setCurrentDocumentsList(state, data) {
            if (!data) {
                state.documentsList = []
            } else {
                state.documentsList = data
            }
        },
        setListMeta(state, data) {
            console.log()
            state.documentsListMeta = data
        },
        setFilter(state, data) {
            state.currentFilter = {
                typeID: data.typeID,
                fields: data.fields.slice(),
                startDate: data.startDate,
                endDate: data.endDate,
                activeType: data.activeType,
            }
        },
        onDestroyDoc(state) {
            state.currentDocument = {}
        },
        setListLoading(state, val) {
          state.documentsListLoading = val
        },
        setDocLoading(state, val) {
          state.currentDocumentLoading = val
        },
    },
    actions: {
        async fetchCurrentDocument(ctx, docID) {
            ctx.commit('setDocLoading', true)
            ctx.commit('setAccess', true)
            documentCurrent(docID, ctx.rootGetters.getAuthToken)
                .then((r) => {
                    ctx.commit('setDocLoading', false)
                    if (r.success) {
                        ctx.commit('setAccess', true)
                        ctx.commit('setCurrentDocument', r.body)
                    } else if (r.status === 403 ) {
                        ctx.commit('setAccess', false)
                    } else {
                        console.log('documentCurrent req not success', r)
                    }
                })
        },
        async fetchDocumentsListMeta(ctx, typeID) {
            ctx.commit('setAccess', true)
            return new Promise(function (resolve) {
                documentsListMeta(typeID, ctx.rootGetters.getAuthToken)
                    .then((r) => {
                        if (r.success) {
                            ctx.commit('setAccess', true)
                            ctx.dispatch('setDocumentsMeta', {
                                typeID: typeID,
                                resp: r.body,
                            })
                        } else if (r.status === 403 ) {
                            ctx.commit('setAccess', false)
                        } else {
                            console.log('documentsListMeta req not success', r)
                        }
                        resolve()
                    })
            })
        },
        async setDocumentsMeta(ctx, d) {
            ctx.commit('setListMeta', d.resp)
            let f = {
                typeID: d.typeID,
                fields: ["c_10003", "c_10012"],
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
                activeType: 'active',
            }
            let f2 = JSON.parse(localStorage.getItem('filter' + d.typeID))
            if (f2 && f2.fields) {
                f = f2
            }
            if (d.resp.lastFilter) {
                f = d.resp.lastFilter
            }
            ctx.commit('setFilter', f)
            ctx.dispatch('fetchDocumentsList')
        },
        async setFilterAndFetch(ctx, filter) {
            // ctx.commit('setFilter', filter)
            localStorage.setItem('filter' + filter.typeID, JSON.stringify(filter))
            ctx.dispatch('fetchDocumentsList')
        },
        async fetchDocumentsList(ctx) {
            let f = ctx.state.currentFilter
            if (f.fields.length === 0) {
                return
            }
            if (!ctx.state.haveAccess) {
                return
            }
            ctx.commit('setListLoading', true)
            documentsList(f, ctx.rootGetters.getAuthToken)
                .then((r) => {
                    if (r.success) {
                        ctx.commit('setCurrentDocumentsList', r.body)
                    } else {
                        console.log('documentsList req not success', r)
                    }
                    ctx.commit('setListLoading', false)
                })
        }
    },
    getters: {
        documentsListAvailableFields(state) {
            return state.documentsListMeta.attrs.map(e => {
                return {
                    text: e.name,
                    value: e.key,
                }
            });
        },
    },
    modules: {}
}