export {getAllPersonal}
import {backendUrl, parseResponse} from "./api";

function getAllPersonal(authToken) {
    return new Promise(function (resolve) {
        fetch(backendUrl + '/api/v1/personal/all', {
            headers: {
                Authorization: 'Bearer ' + authToken,
            }
        }).then((response) => {
            parseResponse(response)
                .then((r) => resolve(r))
        })
    })
}