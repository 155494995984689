export {configurationTypeData, configurationAttributes, configurationStates, configurationReference}

import {backendUrl, parseResponse} from "./api";

function configurationTypeData(typeID, authToken) {
    return new Promise(function (resolve) {
        fetch(backendUrl + "/api/v1/configuration/documents/" + typeID, {
            headers: {
                Authorization: 'Bearer ' + authToken,
            }
        }).then((response) => {
            parseResponse(response)
                .then((r) => resolve(r))
        })
    })
}

function configurationAttributes(typeID, authToken) {
    return new Promise(function (resolve) {
        fetch(backendUrl + "/api/v1/configuration/documents/" + typeID + "/attributes", {
            headers: {
                Authorization: 'Bearer ' + authToken,
            }
        }).then((response) => {
            parseResponse(response)
                .then((r) => resolve(r))
        });
    })
}

function configurationStates(typeID, authToken) {
    return new Promise(function (resolve) {
        fetch(backendUrl + "/api/v1/configuration/documents/" + typeID + "/states", {
            headers: {
                Authorization: 'Bearer ' + authToken,
            }
        }).then((response) => {
            parseResponse(response)
                .then((r) => resolve(r))
        });
    })
}

function configurationReference(ID, authToken) {
    return new Promise(function (resolve) {
        fetch(backendUrl + "/api/v1/configuration/reference/" + ID, {
            headers: {
                Authorization: 'Bearer ' + authToken,
            }
        }).then((response) => {
            parseResponse(response)
                .then((r) => resolve(r))
        });
    })
}