<template>
  <v-app>
    <v-navigation-drawer
        v-model="nav"
        app
        fixed
        width="300"
        :clipped="$vuetify.breakpoint.lgAndUp"
        disable-resize-watcher
    >
      <v-text-field
          v-model="search"
          label="Поиск..."
          solo
          flat
          filled
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
      ></v-text-field>
      <v-treeview
          :items="appNavigationItems"
          dense
          class="text-left"
          :search="search"
          shaped
          hoverable
          activatable
          open-on-click>
        <template v-slot:label="{ item }">
          <v-tooltip bottom open-delay="700">
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs" v-if="item.children">{{ item.name }}</span>
              <router-link v-else :to="docTypeIdToConfigurationUrl(item.typeId)" v-slot="{href}" custom>
                <a :href="href" style="color: #000 !important; text-decoration:none;">{{ item.name }}</a>
              </router-link>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </template>
      </v-treeview>
    </v-navigation-drawer>
    <v-app-bar
        dense
        app
        fixed
        :clipped-left="$vuetify.breakpoint.lgAndUp"
    >
      <v-app-bar-nav-icon v-if="authorized" v-model="nav" @click.stop="nav = !nav && authorized"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/" v-slot="{href}" custom>
          <a :href="href" style="color: #000 !important; text-decoration:none;">WEB EQM</a>
        </router-link>
      </v-toolbar-title>
        <router-link to="/search" v-slot="{href}" custom class="mx-3" v-if="user && user.isAdmin">
          <a :href="href" style="color: #000 !important; text-decoration:none;">Поиск</a>
        </router-link>
      <v-spacer />
      <v-divider v-if="authorized" vertical inset />
      <span class="ml-4" v-if="user">{{ user.fullName }}</span>
      <v-btn
          v-if="authorized"
          icon
          @click="logout"
      >
        <v-icon>
          mdi-exit-to-app
        </v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<script>
export default {
  data: function () {
    return {
      nav: false,
      search: null,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'WEB EQM';
        if (!this.authorized) {
          this.$store.commit('setLoginFromLocalStore')
        }
        if (!this.authorized && to.path !== '/login') {
          this.$router.push({path: '/login', query: { redirect: to.path }})
        }
      }
    },
    authorized: function (val) {
      if (!val) {
        this.nav = false
        this.$router.push({ path: '/login' })
      } else {
        this.$store.dispatch('fetchDocumentsNavigation');
      }
    },
  },
  computed: {
    appNavigationItems() {
      return this.$store.getters.appNavigationItems;
    },
    authorized() {
      return this.$store.getters.isAuthorized;
    },
    user() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    docTypeIdToConfigurationUrl(id) {
      return "/documents/" + id + "/list"
    },
    logout() {
      this.$store.dispatch('logout')
      this.$router.push({ path: '/login' })
    }
  },
  mounted() {
    if (this.authorized) {
      this.$store.dispatch('fetchDocumentsNavigation');
    }
  }
}
</script>
