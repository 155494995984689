import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: "WebEqm",
    }
  },
  {
    path: '/documents/:typeId/configuration',
    name: 'Configuration',
    component: () => import('../views/Configuration.vue'),
    meta: {
      title: "Конфигурация типа",
    }
  },
  {
    path: '/documents/:typeID/list',
    name: 'DocumentsList',
    component: () => import( '../views/DocumentsList.vue'),
    meta: {
      title: "Список документов",
    }
  },
  {
    path: '/documents/:typeID/create',
    name: 'DocumentCreate',
    component: () => import( '../views/DocumentCreate.vue'),
    meta: {
      title: "Новый документ",
    }
  },
  {
    path: '/documents/:typeID/:docID/main',
    name: 'DocumentMain',
    component: () => import('../views/DocumentView/Main.vue'),
    meta: {
      title: "Документ",
    },
    alias: ['/documents/:typeID/:docID/']
  },
  {
    path: '/documents/:typeID/:docID/comments',
    name: 'DocumentComments',
    component: () => import('../views/DocumentView/Comments.vue'),
    meta: {
      title: "Комментарии",
    }
  },
  {
    path: '/documents/:typeID/:docID/files',
    name: 'DocumentFiles',
    component: () => import('../views/DocumentView/Files'),
    meta: {
      title: "Файлы",
    }
  },
  {
    path: '/reference/:id',
    name: 'Reference',
    component: () => import('../views/Reference.vue'),
    meta: {
      title: "Справочник",
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: "Авторизация",
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search'),
    meta: {
      title: "Поиск",
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
